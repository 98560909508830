@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height:100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #000000; */
}

.overlay-logo {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 15%;
  max-height: 80px;
  -webkit-filter: drop-shadow(0 2px 3px rgba(0,0,0,0.12));
  filter: drop-shadow(0 2px 3px rgba(0,0,0,0.12));
}

.hud-instructions-toggle {
  z-index: 1000;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 1.3em;
  font-weight: bolder;
  cursor: pointer;
  background: rgba(0,0,0,0.9);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  color: #fff;
}

.hud-take-picture {
  z-index: 1000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #fff;
}
.hud-take-picture button, .hud-take-picture p {
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 0 auto;
  border-radius: 26px;
  width: 45px;
  height: 45px;
  line-height: 35px;
  background: rgba(0,0,0,0.9);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border: none;
  /* font-size: 1.1em; */
  font-size: 16px;
  color: #fff;
  outline: none;
  font-weight: bolder;
}
.hud-take-picture p {
  line-height: 45px;
}

.hud-instructions {
  z-index: 1000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 26px;
  padding: 20px;
  cursor: pointer;
  width: 80%;
  max-width: 590px;
  background: rgba(0,0,0,0.9);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  color: #fff;
}
.hud-instructions button {
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 23px auto 15px auto;
  padding: 9px 25px;
  border-radius: 26px;
  border: none;
  background: #fff;
  color: #000;
  outline: none;
}
.hud-instructions .credits-link {
  position: absolute;
  right: 19px;
  bottom: 9px;
  font-size: 0.8em;
  color: #fff;
  opacity: 0.6;
  text-decoration: none;
}
.hud-instructions .credits-link:hover {
  opacity: 0.8;
}

.hud-messages {
  z-index: 1000;
  position: absolute;
  top: 100px;
  text-align: center;
  width: 400px;
  max-width: 100%;
}
.hud-messages .message {
  display: block;
  margin: 7px auto;
  border-radius: 26px;
  padding: 8px 17px;
  cursor: pointer;
  background: rgba(255, 119, 0, 0.5);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  color: #fff;
}

.hud-bottom {
  z-index: 1000;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  justify-content: center;
  gap: 17px;
}

.hud-bottom .color-label {
  margin: 3px 0;
  border-radius: 26px;
  padding: 7px 17px 7px 3px;
  cursor: pointer;
  background: rgba(0,0,0,0.9);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  color: #fff;
}
.hud-bottom .color-label.selected {
  cursor: default;
  background: rgba(255,255,255,0.5);
  color: #000;
}

.hud-bottom .density-label {
  margin: 3px 0;
  border-radius: 26px;
  padding: 9px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;
  background: rgba(0,0,0,0.9);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  color: #fff;
}
.hud-bottom .density-label select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
  padding-right: 29px;
  color: #fff;
  border: 0;
  font-size: inherit;
  outline: none;
  position: relative;
}

.hud-bottom .color-label .color-image-badge {
  border-radius: 100%;
  height: 29px;
  width: 29px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin-right: 7px;
}

.output_canvas {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  cursor: default;
}

.instructions-list {
  margin: 0;
  padding: 0;
}
.instructions-list li {
  list-style: none;
}

.flash {
  height: 100%;
  width: 100%;
  background: #fff;
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 1000;
}
.flash.visible {
  opacity: 1;
  -webkit-animation: flash 0.05s;
  animation: flash 0.05s;
}
@-webkit-keyframes flash {
  90% { opacity: .3; }
  100% { opacity: 1; }
}
@keyframes flash {
  90% { opacity: .3; }
  100% { opacity: 1; }
}

.color-image-badge {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 3px 6px rgba(87,87,87,0.16), 0 3px 6px rgba(87,87,87,0.23);
  margin-right: 12px;
}
